
<template>
<div>
  <div v-if="!isLoading" class="row gy-2">
    <div class="col-12">
      <div v-if="subscription">
        <h5 class="mb-3">Billing</h5>
        <div class="card mb-5">
          <div class="card-body">
            <div class="row gy-3 justify-content-between align-items-center">
              <div class="col-sm-4" v-if="subscription.payment_method">
                <payment-method-card :item="subscription.payment_method"/>
              </div>
              <div class="col-sm-4 text-sm-end">
                <button @click="setupEdit()" class="btn btn-primary">Change payment method</button>
              </div>
            </div>
          </div>
        </div>
        <div>
          <h5 class=""> {{subscription.plan.title}} Plan subscription at ${{$filters.money_format(subscription.plan.actual_price)}} USD  per {{subscription.plan.billing_period}}</h5>
          <p>Subscription current period began on <strong>{{$filters.date_time(subscription.current_period_start, 1)}}</strong>. Next renewal date:  <strong>{{$filters.date_time(subscription.current_period_end, 1)}}.</strong></p>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="row gy-3 justify-content-between">
              <div class="col-12">
                <h5 class="mb-4">Payment History</h5>
                <div class="table-responsive">
                  <table class="table mb-0">
                      <thead class="table-light">
                          <tr>
                            <th scope="col">Details</th>
                            <th scope="col">Date</th>
                            <th scope="col">Price</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr v-for="(payment, inIndex) in subscription.payments" :key="inIndex">
                            <td><div style="min-width: 10rem; max-width: 20rem;">{{payment.description}}</div></td>
                            <td><div style="min-width: 6rem;">{{$filters.date_time(payment.payment_date, 1) }}</div></td>
                            <td><div style="min-width: 3rem;">${{$filters.money_format(payment.paid_amount)}}</div></td>
                          </tr>
                      </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <subscription-call v-else />
    </div>
    <b-modal hide-footer v-model="modalCreditCard" title="Change payment method">
      <stripe-payment-form :config="config" v-if="modalCreditCard"
      @setupConfirmed="updatePaymentMethod($event)" btnText="Update Payment Method" />
    </b-modal>
  </div>
  <is-loading v-if="isLoading" />
</div>
</template>

<script>

import IsLoading from '@/components/IsLoading.vue'
import SubscriptionCall from '@/components/SubscriptionCall.vue'
import PaymentMethodCard from '@/components/cards/PaymentMethodCard.vue'
import StripePaymentForm from '@/components/forms/StripePaymentForm.vue'

export default {
  components:{
    IsLoading,
    SubscriptionCall,
    PaymentMethodCard,
    StripePaymentForm
  },
  data(){
    return {
      modalCreditCard: false,
      isLoading: true,
      config:{}
    }
  },
  computed:{
    activeUser(){
      return this.$store.state.activeUser
    },
    subscription(){
      return this.$store.state.subscription
    },
  },
  methods:{
    setupEdit(){
      this.$store.dispatch('loadStripe')
      this.$store.dispatch("changeLoaderValue", true)
      this.$http.get(this.absoluteUrl('/api/stripe/setup'))
        .then(response => {
        this.$store.dispatch("changeLoaderValue")
          if(response.data.success){
            this.config = response.data.data.config
            this.modalCreditCard = true
          }
      })
    },
    updatePaymentMethod(setup){
      let formData = new FormData();
      formData.append('setup_id', setup.id);
      formData.append('type', "payment-method");
      this.$store.dispatch("changeLoaderValue", true)
      this.$http.post('/subscriptions/update', formData)
          .then(response => {
          this.$store.dispatch("changeLoaderValue")
          if(response.data.success){
            this.$store.commit("UPDATE_SUBSCRIPTION", response.data.data)
            this.modalCreditCard = false
          }
      })
    }
  },
  mounted(){
    this.$store.dispatch("fetchSubscription")
    .then((_) => { this.isLoading = false})
  }
}

</script>

<style lang="scss">

</style>
