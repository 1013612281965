<template>
<div class="d-flex py-3 justify-content-center">
  <div class="spinner-border" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>
</template>

<script>
export default {

}
</script>

<style>

</style>