<template>
  <div class="text-center py-5" >
        <h4>You don't have subscription</h4>
        <p> No hiring, no hassle, just quality we have assembled the top creative talent <br> from 15+ countries all over the world to create your next big thing!</p>
        <a :href="absoluteUrl('/express')" class="btn btn-primary" target="_blank">Subscription now </a>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>